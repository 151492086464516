<template>
    <div class="root-wrap">
        <div class="header-wrap">
            <Header type="light" round></Header>
            <div class="ribbon fx-row">
                <div @click="goOrder">
                    <img class="ribbon__img" src="@/assets/images/order/order.png" />
                    <span>订单</span>
                </div>
                <div @click="toCustomer">
                    <img class="ribbon__img" src="@/assets/images/order/customer.png" />
                    <span>客服</span>
                </div>
                <!-- <div @click="toShare">
                    <img class="ribbon__img" src="@/assets/images/order/coupon2.png" />
                    <span>领优惠券</span>
                </div> -->
            </div>
        </div>
        <div class="body-wrap fx-fill">
            <div class="fill-box">
                <van-tabs v-model="activeIndex" class="fx-column" :scrollspy="false" :lazy-render="false" sticky :ellipsis="false" style="height: 100%;" :line-width="18">
                    <van-tab title="热映">
                        <div class="movie_tab-wrap fill-box">
                            <movie-list :list="hotMovieList" @buy="buyTicket" @detail="goDetail"></movie-list>
                        </div>
                    </van-tab>
                    <van-tab title="影院">
                        <div class="movie_tab-wrap fill-box fx-column">
                            <screen-area @change="getCinemaList" @change-hall="getCinemaList"></screen-area>
                            <div class="fx-fill" style="position: relative;">
                                <div class="fill-box" v-infinite-scroll="loadMore" infinite-scroll-disabled="loading" infinite-scroll-distance="10">
                                    <cinema-table :list="cinemaList" @click="toCinema"></cinema-table>
                                </div>
                            </div>
                        </div>
                    </van-tab>
                    <van-tab title="即将上映">
                        <div class="movie_tab-wrap fill-box">
                            <movie-list :list="futureMovieList" type="future" @detail="goDetail"></movie-list>
                        </div>
                    </van-tab>
                </van-tabs>
            </div>
        </div>
        <div class="footer-wrap">
            <tab-bar></tab-bar>
        </div>
    </div>
</template>

<script>
import { getHotMovieList, getSoonMovieList } from '@api/movie-request'
import { getCinemaList} from '@api/cinema-request'
import { getUserLocation } from "@/libs/map-utils.js";
import { mapGetters } from 'vuex';
import Header from '@/components/header';
import TabBar from "@/components/tab-bar";
import ScreenArea from '@/components/cinema/screen-area';
import MovieList from '@/components/movie/movie-list';
import CinemaTable from '@/components/cinema/cinema-table';
import pagination from '@/components/mixins/pagination';
export default {
    mixins: [pagination],
    data() {
        return {
            activeIndex: 0,
            hotMovieList: [],
            futureMovieList: [],
            bannerList: [],
            cinemaList: [],
        }
    },
    computed: {
        ...mapGetters([
            'position',
            'currentCityCode',
            'currentCityName',
            'currentCountyCode',
            'currentCountyName',
            'currentHallCode',
            'appKey',
            'authCode'
        ]),
        selectedLocation() {
            return this.$store.state.app.selectedLocation;
        },
        serviceTel() {
            return this.$store.getters.baseData.serviceTel;
        }
    },
    watch: {
        'currentCityCode': {
            handler() {
                this.initPage();
            }
        },
        'position': {
            handler() {
                this.initPage();
            }
        }
    },
    methods: {
        buyTicket(movieId, movieName) {
            this.$goPage('cinema', {
                movieId,
                movieName: movieName
            })
        },
        goDetail(movieId) {
            this.$goPage('movie-detail', {
                movieId,
            })
        },
        getHotMovieList() {
            let params = {
                citycode: this.currentCityCode,
            }
            getHotMovieList(params).then((res) => {
                this.hotMovieList = res.list;
            })
        },
        getSoonMovieList() {
            let params = {
                citycode: this.currentCityCode,
                per: 100,
            }
            getSoonMovieList(params).then((res) => {
                this.futureMovieList = res.list;
            })
        },
        getCinemaList(refresh) {
            if (refresh) {
                this.cinemaList = [];
                this.getDefaultPagination();
            }
            let params = {
                cityCode: this.currentCityCode,
                countyCode: this.currentCountyCode,
                countyName: this.currentCountyName,
                longitude: this.position ? this.position.lng : '',
                latitude: this.position ? this.position.lat : '',
                pageNo: this.pagination.pageNo,
            }
            if (this.currentHallCode) {
                params.specialHallId = this.currentHallCode;
            }

            getCinemaList(params).then((res) => {
                this.cinemaList = this.cinemaList.concat(res.records);
                this.copyPagination(res);
            }).catch((err) => {
                console.log('请求影院', err)
            })
        },
        getBannerList() {
            let params = {
                type: "newwap",
                device: 'wxmicro',
                citycode: this.currentCityCode,
            };

            this.$service.getBannerList(params).then((res) => {
                this.bannerList = res.slide;
            }).catch((err) => {
                console.log('请求banner', err)
            });
        },
        handleSwipe(item) {
            //TODO
            this.$goPage('cinema', {
                movieId: item.relateid
            })
        },
        loadMore() {
            if (this.pagination.next) {
                ++this.pagination.pageNo;
                this.getCinemaList();
            }
        },
        toCinema(cinemaId) {
            this.$goPage('movie-session', {
                cinemaId
            });
        },
        toCustomer() {
            window.location.href = `tel: ${this.serviceTel}`;
        },
        toShare() {
            this.$goPage('user-share')
        },
        goOrder() {
            if (!this.authCode) {
                this.$toast('您还未登录')
                return;
            }
            this.$goPage('movie-order');
        },
        initPage() {
            // if (!this.position) {
            // }
            // this.getBannerList();
            this.getDefaultPagination();
            this.getHotMovieList();
            this.getSoonMovieList();
            this.getCinemaList(true);
        },
    },
    mounted() {
        // getHotMovieList();
        this.initPage();
        // this.$store.commit('setSelectedCounty', null);
        getUserLocation();
        // if (this.appKey === 'ykdyp134') {
        //     // this.$store.commit('setTitle', '悦看电影票')
        //     //modify 2022-5-17 10:10:46 老的wp改成悦看的跳转到新链接
        //     window.location.href = 'https://wap.xuankua.cn/wp/index?appKey=ykdyp134'
        // }
    },
    components: {
        Header,
        TabBar,
        MovieList,
        ScreenArea,
        CinemaTable
    }
}
</script>

<style lang="scss" scoped>
.header-wrap {
	.ribbon {
		background-color: #fff;
		padding: 8px 0;
		> div {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-left: 20px;
			.ribbon__img {
				width: 40px;
				height: 40px;
			}
		}
	}
}
.body-wrap {
	position: relative;
	/deep/ .van-tabs__wrap {
		height: 30px;
	}
	/deep/ .van-tabs__content {
		flex: 1;
		display: flex;
		flex-direction: column;
		.van-tab__pane {
			flex: 1;
			position: relative;

			.movie_tab-wrap {
			}
		}
	}
}
.footer-wrap {
}
</style>
