<template>
    <div class="component-wrap">
        <template v-if="type == 'hot'">
            <div class="movie_list-item fx-row" v-for="item, index in data" :key="index" @click="$emit('detail', item.id)">
                <div class="movie_item-poster fx-middle">
                    <!-- <img  class="poster" style="width: 88px; height: 105px;object-fit: fill;" :src="item.logo"/> -->
                    <van-image :src="item.logo + '_180x180'" width="80" height="105" fit="fill" ref="poster" class="poster" lazy-load></van-image>
                    <span v-if="item.edition" class="movie_item-showmark">{{item.edition}}</span>
                </div>
                <div class="movie_item-detail fx-fill fx-column fx-between" ref="movieDetail">
                    <span class="movie-name">{{item.name}}</span>
                    <div v-if="item.rating && item.rating > 0" class="fx-center fx-row">
                        <span>电影评分</span>
                        <span class="movie-rate">{{item.rating}}</span>
                    </div>
                    <span>导演：{{item.director}}</span>
                    <span>主演：{{item.actors}}</span>
                    <span v-if="item.type">类型：{{item.type | format}}</span>
                </div>
                <div class="fx-center-middle">
                    <van-button type="primary" style="padding: 0 5px;" color="#f5b225" round size="small" class="btn-small" @click.stop="$emit('buy', item.id, item.name)">购票</van-button>
                </div>
            </div>
        </template>
        <template v-else-if="type == 'future'">
            <template v-for="item, index in playDateMap.keys()">
                <div :key="index">
                    <div v-if="getDateDiff(item) < 8" class="movie-week fc-tips">{{$getCalendar(item)}}</div>
                    <div class="movie-day">{{$momentFormatter(item, 'M月D日')}}</div>
                </div>
                <div class="movie_list-item fx-row" v-for="item in playDateMap.get(item)" :key="item.id" @click="$emit('detail', item.id)">
                    <div class="movie_item-poster fx-middle">
                        <van-image :src="item.logo + '_180x180'" width="80" height="105" fit="fill" ref="poster" class="poster" lazy-load></van-image>
                        <!-- <van-image :src="item.logo + '_180x180'" fit="cover" ref="poster" class="poster"></van-image> -->
                    </div>
                    <div class="movie_item-detail fx-fill fx-column fx-between" ref="movieDetail">
                        <span class="movie-name">{{item.name}}</span>
                        <!-- <div v-if="item.rating && item.rating > 0" class="fx-center fx-row">
                            <span>淘票票评分</span>
                            <span class="movie-rate">{{item.rating}}</span>
                        </div> -->
                        <span>{{$getCalendar(item.playdate, 0, 1)}}上映</span>
                        <span>导演：{{item.director}}</span>
                        <span>主演：{{item.actors}}</span>
                        <span v-if="item.type">类型：{{item.type | format}}</span>
                    </div>
                    <div class="fx-center-middle">
                        <van-button v-if="item.buytype == 'buy'" :color="$config.style.primaryColor" style="padding: 0 18px;" round size="small" class="btn-small" @click.stop="$emit('buy', item.id, item.name)">购票</van-button>
                        <van-button v-else-if="item.buytype == 'prebuy'" :color="$config.style.blueColor" style="padding: 0 18px;" round size="small" class="btn-small" @click.stop="$emit('buy', item.id, item.name)">预售</van-button>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import { getDateDiff } from '@/libs/date-utils'
export default {
    props: {
        list: Array,
        type: {
            type: String,
            default() {
                return 'hot';
            }
        }
    },
    data() {
        return {
            data: [],
            calendarExp: {

            }
        }
    },
    computed: {
        playDateMap() {
            let playDateMap = new Map();
            for (let item of this.list) {
                if (!playDateMap.has(item.playdate)) {
                    playDateMap.set(item.playdate, new Array(item));
                } else {
                    playDateMap.get(item.playdate).push(item);
                    playDateMap.set(item.playdate, playDateMap.get(item.playdate));
                }
            }
            return playDateMap;
        }
    },
    methods: {
        getDateDiff,
        resizeStyle() {
            this.$refs.movieDetail.forEach((item, index) => {
                let poster = this.$refs.poster[index];
                let posterWidth = poster.$el.getBoundingClientRect().width;
                item.style.marginLeft = `${posterWidth + 10}px`;
            })
        },
    },
    watch: {
        'list': {
            handler(val) {
                if (val && val.length > 0) {
                    let list = Object.assign([], val);
                    this.data = list;
                }
            }
        }
    },
    filters: {
        format(val) {
            return val ? val.replace(RegExp(',', 'g'), '/') : '';
        }
    }
}
</script>

<style lang="scss" scoped>
.component-wrap {
	padding: 8px 8px;
	.movie_list-item {
		background-color: #fff;
		border-radius: 5px;
		padding: 12px;
		&:not(:nth-last-child(1)) {
			margin-bottom: 8px;
		}
		.movie_item-poster {
			position: relative;
			.poster {
				border-radius: 6px;
				overflow: hidden;
			}
		}
		.movie_item-showmark {
			background-color: rgba(0, 0, 0, 0.8);
			font-size: 10px;
			color: #fff;
			position: absolute;
			top: 2px;
			left: 2px;
			border-radius: 3px;
			padding: 1px 3px;
			// transform: scale(0.9)
		}
		.movie_item-detail {
			margin-left: 8px;
			width: 0px;
			> * {
				@include txt-ellipsis();
				color: $content-color;
				// line-height: 2;
			}
		}
		.movie-name {
			color: $content-color;
			font-size: 16px;
            font-weight: 700;
		}
		.movie-rate {
			margin-left: 5px;
			color: $type-warning-dark;
			font-size: 16px;
			font-weight: 900;
		}
	}
	.movie-week {
		font-size: 10px;
	}
	.movie-day {
		font-size: 14px;
	}
}
</style>
