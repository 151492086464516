import axios from "../axios";

export function getHotMovieList() {
    const url = "/movie/hot/list";
    return axios.request({
        url,
        method: 'get',
    })
}

export function getSoonMovieList() {
    const url = "/movie/soon/list";
    return axios.request({
        url,
        method: 'get'
    })
}

export function getMovieDetail(data) {
    const url = `/movie${data ? `/${data}` : ''}`;
    return axios.request({
        url,
        method: 'get',
    })
}
